import React, { useState } from 'react';
import './App.css';
import logo from './assets/logo.png';

function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="app">
      <header className="header">
        <div className="titleContainer">
          <h1 className="title">ATOMIC REX</h1>
          <img src={logo} className="logo" alt="logo" />
        </div>
      </header>
      <div className="socialMediaButtons">
        <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">
          <button className="button">Telegram</button>
        </a>
        <a href="https://x.com/atomicrexonbase" target="_blank" rel="noopener noreferrer">
          <button className="button">X</button>
        </a>
        <a href="https://dexscreener.com" target="_blank" rel="noopener noreferrer">
          <button className="button">Dexscreener</button>
        </a>
      </div>
      <div className="mp3Player">
        <button className="button" onClick={handlePlayPause}>
          {isPlaying ? '❚❚' : '▶'}
        </button>
        <audio ref={audioRef} src="https://cyan-top-lobster-928.mypinata.cloud/ipfs/QmULUUGy9rTFZUkNqZEuhB3JQt5TNDvY6CzXczpkJCwuUo"></audio>
      </div>
    </div>
  );
}

export default App;
